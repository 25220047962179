import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import {lightBlue,cyan,red} from '@material-ui/core/colors';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: lightBlue.A400,
            light: lightBlue[200],
            dark: lightBlue[900]
        },
        secondary:{
            main: cyan.A400,
            light: cyan[200],
            dark: cyan[900],
        },
        error: red
    },
    typography:{
        useNextVariants: true,
        fontFamily: "khmer"
    }
});

ReactDOM.render(
    <MuiThemeProvider theme={theme}>
        <App />
    </MuiThemeProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
