import React from 'react';

import { scroller } from 'react-scroll';

import MainMenu from '../components/MainMenu';
import Home from './Home';
import Activity from './Activity';
import Document from './Document';
import Contact from './Contact';
import CommitteeMember from './CommiteeMember';
import About from './About';

export default class ClientArea extends React.Component{
    constructor(props){
        super(props);
        this.state={pageLoad:false};
    }

    componentDidUpdate() {
        this.onRouteChanged();
    }
    componentDidMount(){
        setTimeout(() => {
            this.onRouteChanged();
        }, 800);
    }

    onRouteChanged() {
        let scroll_config={duration: 800,smooth: true, offset: -100};
        let lang=this.props.match.params.lang;
        let url=this.props.location.pathname.replace(new RegExp("/"+lang),"");
        switch(url) {
            case "/home":
                scroller.scrollTo('home_section', scroll_config);
                break;
            case "/about":
                scroller.scrollTo('about_section', scroll_config);
                break;
            case "/member":
                scroller.scrollTo('commiteemember_section', scroll_config);
                break;
            case "/activity":
                scroller.scrollTo('activity_section', scroll_config);
                break;
            case "/document":
                scroller.scrollTo('document_section', scroll_config);
                break;
            case "/contact":
                scroller.scrollTo('contact_section', scroll_config);
                break;
            default:
                break;   
        }
    }
    render(){
        let lang=this.props.match.params.lang;
        return(
            <div style={{width:"100%",paddingLeft:"0px", paddingRight:"0px", marginLeft:"0px", marginRight:"0px"}}>
                
                <span name="home_section" href="#home_section"></span>
                <Home></Home>

                <MainMenu/>
                
                <span name="about_section" href="#about_section"></span>
                <About>{lang==="en"?"About":"អំពីក្រុមការងារយុវជន"}</About>
                
                <span name="commiteemember_section" href="#commiteemember_section"></span>
                <CommitteeMember>{lang==="en"?"Committee Member":"សមាជិក"}</CommitteeMember>
                
                <span name="activity_section" href="#activity_section"></span>
                <Activity>{lang==="en"?"Activity":"សកម្មភាព"}</Activity>
                
                <span name="document_section" href="#document_section"></span>
                <Document>{lang==="en"?"Document":"ឯកសារ"}</Document>
                
                <span name="contact_section" href="#contact_section"></span>
                <Contact>{lang==="en"?"Contact":"ទំនាក់ទំនង"}</Contact>
            </div>
        );
    }
}