import React from 'react';

import Text from './Text';

import {Card,CardContent,Grid} from '@material-ui/core';

export default class Feed extends React.Component{
    render(){
        let message=this.props.data.message;
        let url=this.props.data.url;
        message=message.length>300?(message.substring(0,295).trim()):message;

        ///// use below to prevent substring return partially word
        message=message.substring(0,message.lastIndexOf(" "));
        message+=" ... ";

        let newMessage = message.split('<br/>').map((item, i) => {
            return <span key={i}>{item}<br/></span>;
        });

        return(
            <div style={{marginBottom:"20px"}}>
                <Card style={{ width: '100%' }}>
                    <CardContent>
                        <Grid container spacing={24}>
                            <Grid item sm={4} style={{maxHeight:"160px",marginBottom:"10px", overflowY:"hidden",textAlign:"center"}}>
                                <img style={{width:"100%"}} alt="" src={this.props.data.image} />
                            </Grid>
                            <Grid sm item>
                                <Text size={2} style={{textAlign:"justify"}}>
                                    {newMessage} [<a target='_blank' rel="noopener noreferrer" href={url}>បន្តអាន</a>]
                                </Text>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>
            
        );
    }
}