import React, { Component } from 'react';
import './App.css';

import ClientArea from './pages/ClientArea';
import AdminArea from './pages/AdminArea';

import {BrowserRouter, Route, Switch,Redirect} from 'react-router-dom';

class App extends Component {
  constructor(props){
    super(props);
    ///set language default language to local storage
    if(!localStorage.getItem("lang")){
      localStorage.setItem("lang","en");
    }
    this.state={
      lang: localStorage.getItem("lang")
    };
    
  }

  render() {
    ///////We handle all 404 request in client area
    return (
      <BrowserRouter>
        <div className="App">
          <Switch>
            <Route exact strict path="/" render={()=><Redirect to={"/"+this.state.lang}/>}></Route>
            <Route exact path="/:lang(en|kh)" component={ClientArea}></Route>
            <Route path="/:lang(en|kh)/home" component={ClientArea}></Route>
            <Route path="/:lang(en|kh)/about" component={ClientArea}></Route>
            <Route path="/:lang(en|kh)/member" component={ClientArea}></Route>
            <Route path="/:lang(en|kh)/activity" component={ClientArea}></Route>
            <Route path="/:lang(en|kh)/document" component={ClientArea}></Route>
            <Route path="/:lang(en|kh)/contact" component={ClientArea}></Route>

            <Route path="/mgt" component={AdminArea}></Route>
            
            <Route render={()=><Redirect to={"/"+this.state.lang}/>}></Route>
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
