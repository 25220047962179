import React from 'react';

import Logout from '../pages/Logout';
import TableAdd from '../pages/TableAdd';
import PageNotFound from '../pages/PageNotFound';
import MainMenuAdmin from '../components/MainMenuAdmin';

import {Route, Redirect, Switch} from 'react-router-dom';

export default class AdminArea extends React.Component{
    constructor(props){
        super(props);
        this.state={
            user_level: -1
        };

    }
    render(){
        console.log(sessionStorage.getItem("user_level"));
        if(sessionStorage.getItem("user_level")<7 && this.props.location.pathname!=="/mgt/login"){
            //console.log(this.props);
            return(<Redirect to="/mgt/login"></Redirect>);
        }

        return(
            <div>
                <MainMenuAdmin lang={this.props.match.params.lang}/>
                <Switch>
                    <Route path="/mgt/table/add" component={TableAdd}></Route>
                    <Route path="/mgt/logout" component={Logout}></Route>
                    <Route component={PageNotFound}></Route>
                </Switch>
            </div>
        );
    }
}