import React from 'react';

import { withTheme } from '@material-ui/core/styles';

class SectionHeader extends React.Component{

    render(){
        return(
            <div className="page-section-header" style={{backgroundColor:this.props.theme.palette.primary.main}}>
                <span className="text3">{this.props.children}</span>
            </div>
        );
    }
}

export default withTheme()(SectionHeader);