import React from 'react';

import SectionHeader from '../components/SectionHeader';

import {withTheme}  from '@material-ui/core/styles';

class Contact extends React.Component{
    render(){
        return(
            <div className="page-section">
                <SectionHeader>{this.props.children}</SectionHeader>
                <div className="page-content text2">
                    <div style={{color:this.props.theme.palette.primary.main}}>លេខទូរសព័្ទ ៖</div> 
                    093 99 55 05 <br/>
                    092 55 45 44
                </div>
            </div>
        );
    }
}

export default withTheme()(Contact);