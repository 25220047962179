import React from 'react';

import SectionHeader from '../components/SectionHeader';
import Text from '../components/Text';

import {Table,TableHead,TableBody,TableRow,TableCell,TableFooter,TablePagination} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {blueGrey} from '@material-ui/core/colors';

import DataApi from '../services/DataApi';

import DocumentInfo from '../components/DocumentInfo';

const styles = {
    caption:{
        fontSize: "1.8rem"
    }
};

class Document extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            documents: [],
            page: 0,
            rowsPerPage: 4,
        };
    }

    async componentDidMount () {
        try{
            let documents=await DataApi.getDocument();
            this.setState({
                isLoaded: true,
                documents: documents
            });
        }catch(err){
            this.setState({
                isLoaded: false
            });
        }

    }

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    render(){
        let lang=localStorage.getItem("lang");
        const { rowsPerPage, page } = this.state;
        const totalRow=this.state.documents.length
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, totalRow - page * rowsPerPage);
        const { classes } = this.props;

        return(
            <div className="page-section">
                <SectionHeader>{this.props.children}</SectionHeader>
                <div className="page-content">
                    <Table>
                        <TableHead>
                            <TableRow style={{backgroundColor: blueGrey[50]}}>
                                <TableCell>
                                    <Text bold={true} size={3}>{lang==="en"?"Description":"បរិយាយ"}</Text>
                                </TableCell>
                                <TableCell style={{width:"140px"}}>
                                    <Text bold={true} size={3}>{lang==="en"?"Info":"ព័ត៌មាន"}</Text>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.documents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((file,index) => {
                                    let lang=localStorage.getItem("lang");
                                    let desc=file["description_"+lang];
                                    let open_url=DataApi.getApiUrl()+"/document/open?id="+file.id+"&lang=";
                                    desc=desc?desc:"";
                                    desc=desc.trim()===""?(file["description_"+(lang==="en"?"kh":"en")]):desc;
                                    
                                    return(
                                        <TableRow key={index} data={file}>
                                            <TableCell>
                                                <Text size={2}>{desc}</Text>
                                            </TableCell>
                                            <TableCell>
                                                <DocumentInfo isRender={file.is_render_kh} lang="kh" size={file.size_kh} openUrl={open_url+"kh"}/>
                                                <DocumentInfo isRender={file.is_render_en} lang="en" size={file.size_kh} openUrl={open_url+"en"}/>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 49 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[]}
                                    count={totalRow}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    onChangePage={this.handleChangePage}
                                    classes={{caption: classes.caption}}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(Document);