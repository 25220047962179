import React from 'react';

class Text extends React.Component{

    render(){
        let props={
            bold: '', size:1
        }
        let className=this.props.bold?'bold':props.bold;
        className+=' text'+(this.props.size&&[1,2,3].includes(this.props.size)?this.props.size:props.size);
        return(
            <span className={className}>{this.props.children}</span>
        );
    }
}

export default Text;