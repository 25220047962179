import React from 'react';

import SectionHeader from '../components/SectionHeader';
import Feed from '../components/Feed';
import Text from '../components/Text';

import DataApi from '../services/DataApi';

export default class Activity extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            feeds: []
        };
    }

    async componentDidMount () {
        try{
            let feeds=await DataApi.getFacebookFeed('',3);
            this.setState({
                isLoaded: true,
                feeds: feeds
            });
        }catch(err){
            this.setState({
                isLoaded: false
            });
        }
    }

    render(){
        let fb_link="https://www.facebook.com/mefyouthcpp";
        return(
            <div className="page-section">
                <SectionHeader>{this.props.children}</SectionHeader>
                <div className="page-content">
                    {this.state.feeds.map((feed,index) => (
                        <Feed key={index} data={feed}>
                        </Feed>
                    ))}

                    <div style={{textAlign:"right"}}>
                        <a href={fb_link} target="_blank" rel="noopener noreferrer">
                            <Text size={2}>ចុចត្រង់នេះសម្រាប់សកម្មភាពផ្សេងទៀត</Text>
                        </a>
                    </div>
                </div>
                
            </div>
        );
    }
}