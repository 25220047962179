import React from 'react';
import anime from 'animejs';

import img_Samdach from '../images/home/samdach.png';
import img_Cpp_Logo from '../images/home/logo.png';
import img_bird from '../images/home/bird.png';
import img_phnom_penh from '../images/home/phnom_penh.svg';

import img_bg2 from '../images/home/bg_second.jpg';

import {LinearProgress,Hidden} from '@material-ui/core';

export default class Home extends React.Component {
    constructor(props){
        super(props);
        this.state={
            animeProgress: 0
        }
    }
    
    componentDidMount(){
        let setAnimeProgress=(progress)=>{
            progress=Math.ceil(progress);
            this.setState({animeProgress: progress});
        };

        let anime_timeline1 = anime.timeline({
            easing: 'easeOutQuad',
            loop: false,
            autoplay: false,
            update: function(anim){
                setAnimeProgress(Math.round(anim.progress));
            },
            complete: function(){
                setAnimeProgress(0);
                setTimeout(()=>{anime_timeline2.restart();},500);
            }
        });
        let anime_timeline2 = anime.timeline({
            easing: 'easeOutQuad',
            loop: false,
            autoplay: false,
            update: function(anim){
                setAnimeProgress(anim.progress);
            },
            complete: function(){
                setAnimeProgress(0);
                setTimeout(()=>{anime_timeline1.restart();},500);
            }
        });

        anime_timeline1
            .add({
                targets: ".img-cpp-logo.slide1",
                duration:3000,
                translateX: [-200,0],
                opacity: {value: 1, duration: 1},
            },1)
            .add({
                targets: ".label1.slide1",
                opacity: {value: 1},
                translateX:{value:[-100,0]},
                duration: 2500
            },500)
            .add({
                targets: ".label2.slide1",
                opacity: {value: 1},
                translateX:{value:[100,0]},
                duration: 2500
            },500)
            .add({
                targets: ".img-phnom-penh.slide1",
                opacity: {value: 1, duration: 3000}
            },2000)
            .add({
                targets: ".img-samdach.slide1",
                duration:3000,
                translateY: [0,70],
                opacity: {value: 1, duration: 1000}
            },1500)
            .add({
                targets: ".img-bird.slide1",
                duration:1500,
                translateX: [500,0],
                translateY: [0,70],
                opacity: {value: 1, duration: 1000}
            },2000)
            .add({
                targets: ".slide1",
                opacity: {value: 0, duration: 500},
                
            });

        anime_timeline2
            .add({
                targets: ".img-bg2.slide2",
                opacity: {value: 1, duration: 3000},
                rotateY: {value:["180deg","0deg"],duration:1000}
            })
            .add({
                targets: ".label1.slide2",
                opacity: {value: 1},
                translateX:{value:[-100,0]},
                duration: 2500
            },1500)
            .add({
                targets: ".label2.slide2",
                opacity: 1,
                rotateY:["180deg","0deg"],
                duration:2500
            },1500)
            .add({
                targets: ".slide2",
                delay:2000,
                opacity: {value: 0, duration: 500},
                
            });

        anime_timeline1.restart();

        this.toggleAnimation=(play)=>()=>{
            if(play){
                if(anime_timeline1.paused && anime_timeline1.progress>0 && anime_timeline1.progress<100)anime_timeline1.play();
                if(anime_timeline2.paused && anime_timeline2.progress>0 && anime_timeline2.progress<100)anime_timeline2.play();
            }
            else{
                anime_timeline1.pause();
                anime_timeline2.pause();
                
            }
        }
    }
    toggleAnimation=()=>{}; ////initial here to over later in componentDidMount
    render(){
        return(
            <div onMouseEnter={this.toggleAnimation(false)} onMouseLeave={this.toggleAnimation(true)}>
                <LinearProgress variant="determinate" value={this.state.animeProgress} />
                <div className="page-section" style={{backgroundColor:"#75BAFE",position:"relative",alignItems:"center",display:"flex",flexDirection:"column",overflow:"hidden",marginTop:"0px"}}>
                    <img className="img-phnom-penh slide1" src={img_phnom_penh} alt="" style={{position:"absolute",bottom:"-5px",opacity:"0", width:"100%", maxWidth:"1200px"}}></img>
                    <img className="img-cpp-logo slide1" src={img_Cpp_Logo} alt="" style={{position:"absolute",top:"20px",left:"50%",opacity:"0",width:"140px",marginLeft:"-70px"}}></img>
                    <img className="img-samdach slide1" src={img_Samdach} alt="" style={{position:"absolute",top:"150px",left:"50%",opacity:"0", width:"250px", marginLeft:"-125px"}}></img>

                    <div className="label1 slide1" style={{padding:"10px 20px",color:"#fff", fontSize:"1.6rem", position:"absolute",top:"70px",left:"5%",opacity:"0"}}>
                        <Hidden smDown>គណបក្សប្រជាជនកម្ពុជា<br/>គណៈកម្មាធិការគណបក្សក្រសួងសេដ្ឋកិច្ចនិងហិរញ្ញវត្ថុ</Hidden>
                    </div>
                    <div className="label2 slide1" style={{padding:"10px 20px",color:"#fff", fontSize:"1.6rem", position:"absolute",top:"70px",right:"5%",opacity:"0"}}>
                        <Hidden smDown>ឯករាជ្យ សន្តិភាព សេរីភាព<br/>ប្រជាធិបតេយ្យ អព្យាក្រឹត និងវឌ្ឍនភាពសង្គម</Hidden>
                    </div>
                    
                    <img className="img-bird slide1" src={img_bird} alt="" style={{position:"absolute",top:"110px",left:"50%",opacity:"0", width:"30px",marginLeft:"-90px",transform:"rotateY(-180deg) rotate(-30deg)"}}></img>
                    <img className="img-bird slide1" src={img_bird} alt="" style={{position:"absolute",top:"140px",left:"50%",opacity:"0", width:"25px",marginLeft:"-140px",transform:"rotateY(-180deg)"}}></img>
                    
                    <img className="img-bird slide1" src={img_bird} alt="" style={{position:"absolute",top:"80px",left:"50%",opacity:"0", width:"32px",marginLeft:"50px",transform:"rotate(-30deg)"}}></img>
                    <img className="img-bird slide1" src={img_bird} alt="" style={{position:"absolute",top:"110px",left:"50%",opacity:"0", width:"24px",marginLeft:"80px",transform:"rotate(-25deg)"}}></img>
                    <img className="img-bird slide1" src={img_bird} alt="" style={{position:"absolute",top:"150px",left:"50%",opacity:"0", width:"28px",marginLeft:"100px"}}></img>

                    <div className="img-bg2 slide2" style={{width:"100%",height:"100%",backgroundImage:"url("+img_bg2+")",backgroundRepeat:"no-repeat",backgroundPosition:"center center",backgroundSize:"cover",position:"absolute",top:"0px",left:"0px",opacity:"0"}}></div>

                    <div className="label1 slide2" style={{backgroundColor:"#555",padding:"10px 20px",color:"#fff", fontSize:"1.6rem", position:"absolute",top:"100px",left:"10%",opacity:"0"}}>
                        ក្រុមការងារយុវជនគណបក្សប្រជាជនកម្ពុជា
                    </div>
                    <div className="label2 slide2" style={{backgroundColor:"#555",padding:"10px 20px",color:"#fff", fontSize:"1.6rem", position:"absolute",top:"170px",left:"30%",opacity:"0"}}>
                        ប្រចាំក្រសួងសេដ្ឋកិច្ចនិងហិរញ្ញវត្ថុ
                    </div>
                </div>
            </div>
        );
    }
    
}