import React from 'react';
import Logo from '../images/system/logo.png';
import {NavLink, withRouter} from 'react-router-dom';

class MainMenuAdmin extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            lang: ""
        };
    }

    render(){
        return(
            <div>
                test
                <NavLink></NavLink>
                <img alt="" src={Logo}></img>
            </div>
            
        );
    }
}

export default withRouter(MainMenuAdmin);