import React from 'react';

import Logo from '../images/system/logo.png';
import khIcon from '../images/system/lang_kh.svg';
import enIcon from '../images/system/lang_en.svg';

import Text from './Text';
import {NavLink, withRouter} from 'react-router-dom';

import {AppBar,Toolbar,IconButton,Hidden,SwipeableDrawer,List,ListItem,Divider} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

class MainMenu extends React.Component{
    menuObject=[
        {url: "/about",text_en:"About",text_kh:"អំពីក្រុមការងារយុវជន"},
        {url: "/member",text_en:"Committee Member",text_kh:"សមាជិក"},
        {url: "/activity",text_en:"Activity",text_kh:"សកម្មភាព"},
        {url: "/document",text_en:"Document",text_kh:"ឯកសារ"},
        {url: "/contact",text_en:"Contact",text_kh:"ទំនាក់ទំនង"},
    ];

    constructor(props){
        super(props);
        this.state={
            drawerLeft:false,
            lang: this.props.match.params.lang
        }

        /////need to bind function so that can invoke from child component
        this.toggleLang = this.toggleLang.bind(this);
    }

    toggleDrawer = (drawer, open) => () => {
        this.setState({
          [drawer]: open,
        });
    };

    toggleLang(){
        let new_lang=this.state.lang==="en"?"kh":"en";
        let new_url=this.props.location.pathname.replace(new RegExp(this.state.lang),new_lang);
        this.setState({lang: new_lang});
        localStorage.setItem("lang",new_lang);
        this.props.history.push(new_url);
    }

    render(){
        let lang=this.state.lang;
        let menuObject=this.menuObject;
        return(
                <AppBar position="sticky" color="default">
                    <Toolbar style={{backgroundColor:"#fff"}}>
                        <Hidden mdUp>
                            <IconButton color="inherit" aria-label="Open drawer" onClick={this.toggleDrawer('drawerLeft', true)}>
                                <MenuIcon />
                            </IconButton>
                        </Hidden>
                        <NavLink className="nav-link" to={"/"+lang+"/home"}>
                            <img className="logo" src={Logo} alt="logo"/>
                        </NavLink>
                        <div style={{flexGrow:1}}>
                            <Hidden smDown>
                                <NavLink className="nav-link" to={"/"+lang+"/home"} style={{display:"none"}}>
                                    <Text size={3} bold={true}>{lang==="en"?"Home":"ទំព័រដំបូង"}</Text>
                                </NavLink>
                                {menuObject.map((menu,index) => (
                                    <NavLink key={index} className="nav-link" to={"/"+lang+menu.url}>
                                        <Text size={3} bold={true}>{menu["text_"+lang]}</Text>
                                    </NavLink>
                                ))}
                            </Hidden>
                        </div>
                        <div>
                            <IconButton style={{marginTop:"-5px"}} onClick={this.toggleLang}>
                                <img style={{width:"24px",borderRadius:"3px"}} alt="language" src={this.state.lang==="en"?khIcon:enIcon}></img>
                            </IconButton>
                            
                        </div>
                    </Toolbar>
                    <SwipeableDrawer
                    anchor="left"
                    open={this.state.drawerLeft}
                    onClose={this.toggleDrawer('drawerLeft', false)}
                    onOpen={this.toggleDrawer('drawerLeft', true)}
                    >
                        <div
                            tabIndex={0}
                            role="button"
                            onClick={this.toggleDrawer('drawerLeft', false)}
                            onKeyDown={this.toggleDrawer('drawerLeft', false)}
                        >
                            <div style={{textAlign:"center"}}>
                                <NavLink className="nav-link" to={"/"+lang+"/home"}>
                                    <img className="logo" src={Logo} alt="logo"/>
                                </NavLink>
                            </div>
                            <Divider />
                            <List>
                                {menuObject.map((menu,index) => (
                                    <ListItem button key={index}>
                                        <NavLink className="nav-link" to={"/"+lang+menu.url}>
                                            <Text size={3} bold={true}>{menu["text_"+lang]}</Text>
                                        </NavLink>
                                    </ListItem>
                                ))}
                            </List>
                        </div>
                    </SwipeableDrawer>
                </AppBar>
                
            
        );
    }
}
export default withRouter(MainMenu);