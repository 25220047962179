export default class DataApi{
    /*//////
        static api_url="http://local.cppmef/api";
    /*/////
        static api_url="https://www.youth-cppmef.org/api";
    ///**/
    static async getFacebookFeed(req_url='',limit=6){
        let res=await fetch(this.api_url+'/facebook-post/get?req_url='+req_url+'&limit='+limit);
        //let data=await res.json();
        let data=await res.text();
        data=JSON.parse(data);
        return data.feeds;
    }
    
    static async getDocument(limit=0){
        let res=await fetch(this.api_url+'/document/get?limit='+limit);
        //let data=await res.json(); ///// not use this because some server not return json object
        let data=await res.text();
        data=JSON.parse(data);
        return data.documents;
    }

    static getApiUrl(){
        return this.api_url;
    }
}