import React from 'react';

import Text from '../components/Text';

import SectionHeader from '../components/SectionHeader';
import {Tabs,Tab,AppBar} from '@material-ui/core';

import img_org_chart from '../images/about/organization_chart.png';

class About extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            tab_index: 0,
        };
    }

    handleChange = (event, tab_index) => {
        this.setState({ tab_index });
      };

    render(){
        const { tab_index } = this.state;
        let tabLabelObject=[
            {en:"Background",kh:"ប្រវត្តិ"},
            {en:"Vision/Mission",kh:"ចក្ខុវិស័យ និងបេសកកម្ម"},
            {en:"Our Goal",kh:"គោលបំណង"},
            {en:"Organization Chart",kh:"អង្គការលេខ"}
        ];
        return(
            <div className="page-section">
                <SectionHeader>{this.props.children}</SectionHeader>
                <div className="page-content">
                    <AppBar position="static" color="inherit">
                    <Tabs value={tab_index}
                    onChange={this.handleChange}
                    variant="scrollable"
                    scrollButtons="on"
                    indicatorColor="primary"
                    textColor="primary">
                        {tabLabelObject.map((tab,index)=>(
                            <Tab key={index} label={tab[localStorage.getItem("lang")]} classes={{label:"text3"}} />
                        ))}
                    </Tabs>
                    </AppBar>
                    
                    {tab_index === 0 && <Text size={2}>
                        <p>
                        ការងារយុវជនគណបក្ស នៅគណៈកម្មាធិការគណបក្សក្រសួងសេដ្ឋកិច្ចនិងហិរញ្ញវត្ថុ (កសហវ) បានចាប់ផ្ដើមអនុវត្តចាប់តាំងពីខែមេសា ឆ្នាំ២០១២ ដែលកាលនោះមានឈ្មោះថា <b>“សកម្មជនយុវជនគណបក្ស”</b>។ នៅក្នុងនីតិកាលទី៥ កន្លងមកឯកឧត្តមអគ្គបណ្ឌិតសភាចារ្យប្រធានគណៈកម្មាធិការគណបក្ស កសហវ បានណែនាំឱ្យរៀបចំធ្វើបច្ចុប្បន្នភាពយន្តការការងារយុវជនគណបក្សប្រចាំក្រសួងសេដ្ឋកិច្ចនិងហិរញ្ញវត្ថុ ឱ្យមានរចនាសម្ព័ន្ធគ្រប់គ្រងនិងការទទួលខុសត្រូវកាន់តែច្បាស់លាស់ ដើម្បីឆ្លើយតបនឹងតម្រូវការកម្លាំងយុវជននិងគាំទ្រយុវជនគណបក្សផងដែរ។
                        </p>
                        <p>
                        ក្នុងន័យនេះ ជារចនាសម្ព័ន្ធ គណៈកម្មាធិការគណបក្សប្រចាំ កសហវ មានក្រុមការងារយុវជនគណបក្សប្រជាជនកម្ពុជា មួយជាសេនាធិការ ដែលមានឯកឧត្តមបណ្ឌិត <b>ហ៊ែល ចំរើន</b> ជាប្រធាន និងមានសមាជិកជាប្រធានក្រុមការងារយុវជននៅតាមសាខាបក្សនៃអង្គភាពទាំង ២១ ក្រោមឱវាទក្រសួងសេដ្ឋកិច្ចនិងហិរញ្ញវត្ថុ ហើយដែលអង្គភាព ទាំង ២១ នេះ សុទ្ធតែមាននូវក្រុមការងារយុវជនដោយឡែករៀងៗខ្លួន។
                        </p>
                    </Text>}

                    {tab_index === 1 && <Text size={2}>
                        <p>ចក្ខុវិស័យ ៖</p>
                        <p>
                        យុវជនក្រសួងសេដ្ឋកិច្ចនិងហិរញ្ញវត្ថុ ក្លាយទៅជាកម្លាំងចលករ សាមគ្គី ស្មោះស្ម័គ្រភក្ដី ថ្លៃថ្នូរ រឹងមាំ និងរស់រវើក ក្នុងការដឹកនាំនិងជំរុញផលិតភាពនិងពិពិធភាពសេដ្ឋកិច្ចនិងហិរញ្ញវត្ថុ ដើម្បីជាឧត្តមប្រយោជន៍និងសុខុមាលភាពរបស់ប្រទេសជាតិ និងប្រជាពលរដ្ឋ។
                        </p>
                        
                        <p>បេសកកម្ម ៖</p>
                        <p>
                        ផ្ដល់កាលានុវត្តភាពជូនយុវជនក្នុងការអភិវឌ្ឍសមត្ថភាព និងសម្រេចសក្ដានុពលរបស់ខ្លួន ជាប្រយោជន៍ដល់ការអនុវត្តគោលនយោបាយរបស់គណបក្សនិងរាជរដ្ឋាភិបាល ក្នុងវិស័យសេដ្ឋកិច្ចនិងហិរញ្ញវត្ថុ។
                        </p>
                    </Text>}

                    {tab_index === 2 && <Text size={2}>
                        <ul>
                            <li>បណ្តុះបណ្តាលស្មារតីសាមគ្គីភាពនិងកម្លាំងគាំទ្រមាគ៌ានយោបាយរបស់គណបក្ស</li>
                            <li>ផ្សព្វផ្សាយនិងអប់រំកម្មវិធីនយោបាយបក្ស, កម្មវិធីកែទម្រង់ និងសមិទ្ធផលរបស់គណបក្ស ទាំងកម្រិតគោលនយោបាយជាតិ ទាំងកម្រិតវិស័យសេដ្ឋកិច្ចនិង ហិរញ្ញវត្ថុ</li>
                            <li>គាំទ្រនិងសម្របសម្រួលជាមួយកិច្ចដំណើរការរបស់ កយក នៅក្នុងផ្ទៃក្នុងក្រសួង  ព្រមទាំង ជាមួយស្ថាប័នពាក់ព័ន្ធផ្សេងទៀតប្រកបដោយប្រសិទ្ធភាពនិងស័ក្តិសិទ្ធភាព</li>
                        </ul>
                    </Text>}

                    {tab_index === 3 && <Text size={2}>
                        <p style={{textAlign:"center"}}>
                            <img alt="" src={img_org_chart} style={{width:"80%"}}></img>
                        </p>
                    </Text>}
                
                </div>
            </div>
        );
    }
}

export default About;