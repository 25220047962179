import React from 'react';

import icon_open_new from '../images/system/open_new_icon.svg';
import Text from './Text';

import khIcon from '../images/system/lang_kh.svg';
import enIcon from '../images/system/lang_en.svg';

export default class DocumentInfo extends React.Component{
    render(){
        if(!this.props.isRender)return false;
        let flag=this.props.lang==='en'?enIcon:khIcon;
        return(
            <p>
                <Text size={1}>
                    <img style={{width:"18px",verticalAlign:"middle",borderRadius:"3px"}} alt={this.props.lang} src={flag}></img>
                    <span> | </span>
                    {this.calculateSize(this.props.size)}
                    <span> | </span>
                    <a target="_blank" rel="noopener noreferrer" href={this.props.openUrl}>
                        <img alt="Open New Window" src={icon_open_new} style={{verticalAlign:"middle"}}></img>
                    </a>
                </Text>
            </p>
        );
    }
    calculateSize(size){
        if(size>=1024){
            size=Math.ceil(size/1024);
            if(size>=1024){
                size=Math.ceil(size/1024);
                size+=" MB";
            }
            else{
                size+=" KB";
            }
        }
        else{
            size+=" Bytes";
        }
        return size;
    }
}