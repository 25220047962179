import React from 'react';

import SectionHeader from '../components/SectionHeader';
import Text from '../components/Text';

import img_member_list from '../images/committee_member/member_list.png';

export default class CommitteeMember extends React.Component{
    render(){
        return(
            <div className="page-section">
                <SectionHeader>{this.props.children}</SectionHeader>
                <div className="page-content">
                    <Text size={2}>
                        <p>
                            បច្ចុប្បន្ន យុវជនគណបក្សប្រជាជនកម្ពុជា ប្រចាំក្រសួងសេដ្ឋកិច្ចនិងហិរញ្ញវត្ថុ សម្រាប់ថ្នាក់កណ្ដាល មានសរុបប្រមាណ ២០០០ រូប (ក្នុងនោះ នារី មានប្រមាណ ជា ៦៧០ នាក់ ស្មើនឹង ៣៤%)។
                        </p>
                        <p>    
                            យុវជនសកម្មទាំងនេះ ត្រូវបានសម្រេចទទួលស្គាល់ជាផ្លូវការ ដោយសេចក្ដីសម្រេចរបស់ឯកឧត្តមអគ្គបណ្ឌិសភាចារ្យ <b>អូន ព័ន្ធមុនីរ័ត្ន</b> ប្រធានគណៈកម្មាធិការគណបក្សប្រចាំក្រសួងសេដ្ឋកិច្ចនិងហិរញ្ញវត្ថុ។
                        </p>
                        <img alt="" style={{width: "100%"}} src={img_member_list}></img>
                    </Text>
                </div>
            </div>
        );
    }
}